@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400&display=swap');

/* body {
  font-family: 'Ubuntu', sans-serif !important;
} */

body,
html {
    font-size: 13px !important;
    font-family: 'Ubuntu', sans-serif !important;
    /* font-family: Inter, "Source Sans Pro", Helvetica, Arial, sans-serif !important; */
    height: 100%;
}

#root {
    height: 100%;
}

#root div {}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.css-1zoxb2-MuiTableCell-root.MuiTableCell-body {
    font-size: 13px !important;
}

.light_version {
    background: #ffffff;
    color: #17191c;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

#main-content {
    padding-left: 0;
    height: 100%;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.profile-pic-wrapper {
    /* height: 100vh; */
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pic-holder {
    text-align: center;
    position: relative;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.pic-holder1 {
    text-align: center;
    position: relative;
    border-radius: 15%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.pic {
    width: 100% !important;
}

.pic-holder .pic {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(90, 92, 105, 0.7);
    color: #f8f9fc;
    font-weight: 600;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.pic-holder .upload-file-block {
    cursor: pointer;
}

.pic-holder:hover .upload-file-block {
    opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
    display: none;
}

.pic-holder.uploadInProgress .upload-loader {
    opacity: 1;
}

.pic-holder1 .pic {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.pic-holder1 .upload-file-block,
.pic-holder1 .upload-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(90, 92, 105, 0.7);
    color: #f8f9fc;
    font-weight: 600;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.pic-holder1 .upload-file-block {
    cursor: pointer;
}

.pic-holder1:hover .upload-file-block {
    opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
    display: none;
}

.pic-holder1.uploadInProgress .upload-loader {
    opacity: 1;
}


/* .DatePicker__input{
  display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 0px 0px 12px 90px!important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

.picker_container {
    width: 160px !important;
    height: 160px !important;
    margin: 0 20px 20px;
    border-radius: 50%;
    background-color: #f0f0f0;
    position: relative;
}

.picker_point .point_outter {
    transform: translateX(-150%) rotate(60deg) !important;
}

.custom-tab-2 .nav-link .active {
    border-top: 2px solid #95cc47;
}

.badge-success {
    background-color: #95cc47;
    color: #212529;
    font-weight: 500;
}

.badge-danger {
    background-color: #f05050;
    color: #f0f0f0;
    font-weight: 500;
}

.ag-cell-focus,
.ag-cell-no-focus {
    border: none !important;
}


/* This CSS is to not apply the border for the column having 'no-border' class */

.no-border.ag-cell:focus {
    border: none !important;
    outline: none;
}

.MuiGrid-justify-content-xs-space-around {
    justify-content: 'left' !important;
}

.costom-border-bottom {
    border-bottom: 1px solid #000000 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-form-control {
    border-bottom: 1px solid #ced4da !important;
    border: none;
    width: 75px;
    border-radius: 0;
}

.border-none {
    border: none !important;
}

.font-size-12 {
    color: #000;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.font-size-14 {
    color: #000;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.text-black {
    color: #000 !important;
}

.text-blue {
    color: rgb(42, 53, 206) !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.bg-white {
    /* background: #fff; */
    color: #000 !important;
}

.customBlur {
    position: relative;
}

.customBlur::before {
    content: "";
    position: absolute;
    background-color: #f2f2f261;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
}

select.form-control:not([size]):not([multiple]) {
    height: calc(1.5em + .75rem + 2px) !important;
}

.customColumnAlign {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.customHr {
    border-top: 1px solid rgba(51, 47, 47, 0.1);
}

.ag-root {}

.ag-row {
    font-size: 14px !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #000 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}

.btn-primary-custom {
    color: #000 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    font-weight: bold !important;
}

.css-1p823my-MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px !important;
    padding-right: 16px;
}

.ag-react-container {
    cursor: pointer;
}

.metismenu a {}

.metismenu ul a {
    font-size: 13px;
}


/* .sidebar-scroll{ overflow-y: scroll !important;} */

.form-control {
    height: 38PX;
}

.css-1wgdqn3-MuiTableCell-root {}

.css-1qrqnwd-MuiTableCell-root {
    font-size: 10px !important;
}

#main-content .card {
    margin-bottom: 0;
    padding: 5px 0;
}

.h4,
h4 {
    font-size: 1rem;
}

.card-wrapper.flip-left .card .back {
    transform: rotateY(-180deg);
    color: #fff;
}

.col-form-label {
    font-size: 13px;
}

.font-xs {}

.form-check-label {
    margin-bottom: 0;
}

.nav.nav-tabs2>li>a {
    padding: 0.5rem 1.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-group {
    margin-bottom: 0.5rem;
}

.theme-cyan .card .header h2 {
    color: #17C2D7;
    font-weight: 400;
    font-size: 12px;
}

.block-header .breadcrumb {
    font-size: 12px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 14px !important;
}

.btn {
    font-size: 12px;
    font-weight: 300;
}

#main-content {
    width: calc(100% - 184px);
}

.light_version .top-navbar {
    width: calc(100% - 184px);
}

#left-sidebar {
    width: 184px;
}

.toggle {
    white-space: pre-wrap;
}

#ember164 {
    float: right;
}

#ember171 {
    float: right;
}

.ac-box {
    position: relative;
    display: block;
    zoom: 1;
    vertical-align: middle;
}

.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
    font-size: 12px;
}

.ac-box .ac-selected {
    height: 30px;
    margin-bottom: 2px;
}

.ac-box .ac-selected span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ac-box .ac-selected div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%;
    border-left: none;
    border-radius: 0 4px 4px 0;
}

.zf-ac-toggler {
    right: 10px;
    display: inline-block;
    position: absolute;
    fill: currentColor;
    color: #999;
    color: #999;
    top: 0;
}

.pricebooks-items-table.table thead>tr>th {
    background-color: #f5f5f5;
    color: #263035;
    font-size: 12px;
    text-transform: uppercase;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    font-weight: 600;
}

.pricebooks-items-table.table tbody>tr>td,
.pricebooks-items-table.table thead:first-child tr:first-child th {
    padding: 8px 10px;
}

.pricebooks-items-table td {
    word-wrap: break-word;
}

.table td,
.table th {
    padding: 8px;
    vertical-align: top;
    border-top: 1px solid #eee;
    font-weight: 600;
}

.sms-custom-editor svg.icon.sms-info,
svg.icon.icon-sm {
    color: #fff;
}

.recipe {
    height: 14px;
    width: 14px;
    margin-top: -20px;
}

svg.icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
}

.roundOffToopTipClass1 {
    inset: -71px auto auto 20px;
    visibility: visible;
    width: 350px;
}

.customReactSelectMenu {
    z-index: 11000;
}


/* .rdt_TableBody{
  overflow: auto !important;
    height: 500px !important;
} */

.customReactSelectMenu {
    background-color: #fff !important;
}

.change-data {
    background-color: #fff;
    border: 1px double #eee;
    color: rgb(41, 146, 208);
    padding: 20px 10px 25px 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 4px 4px 3px #999999;
}

.fetching {
    display: flex;
    justify-content: center;
    font-size: 30px;
    padding: 20px 0;
    font-weight: 200;
}

.label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-option-wrapper {
    display: flex;
    align-items: center;
}

.selected-option {
    margin-right: 10px;
}

.cursor {
    cursor: pointer;
}

.input-group .css-1s2u09g-control {
    min-height: 30px !important;
}

.itemListOverlay {
    z-index: 9;
    margin: 30px;
    background: #009938;
}

.itemListBox {
    position: relative;
    z-index: 1;
}

.itemListBox::before {
    position: absolute;
    content: "";
    background-color: rgb(0 0 0 / 3%);
    bottom: 0;
    z-index: 11000;
    top: 0;
    left: 0;
    right: 0;
}

@font-face {
    font-family: 'WebFont-Ubuntu';
    src: local(Ubuntu), url(https://fonts.gstatic.com/s/ubuntu/v10/4iCs6KVjbNBYlgoKcg72nU6AF7xm.woff2);
}

.pcs-template {
    font-family: Ubuntu, 'WebFont-Ubuntu';
    font-size: 8pt;
    color: #000000;
    background: #ffffff;
}

.pcs-header-content {
    font-size: 8pt;
    color: #000000;
    background-color: #ffffff;
}

.pcs-template-body {
    padding: 0 0.400000in 0 0.550000in;
}

.pcs-template-footer {
    height: 0.100000in;
    font-size: 6pt;
    color: #000000;
    padding: 0 0.400000in 0 0.550000in;
    background-color: #ffffff;
}

.pcs-footer-content {
    word-wrap: break-word;
    color: #000000;
    border-top: 1px solid #9e9e9e;
}

.pcs-label {
    /* color: #ffffff; */
    font-Weight: 800;
    font-size: small
}

.pcs-entity-title {
    font-size: 22pt;
    color: #000000;
}

.pcs-orgname {
    font-size: 12pt;
    color: #000000;
}

.pcs-customer-name {
    font-size: 9pt;
    color: #000000;
}

.pcs-eori-number {
    color: #333;
    margin: 0;
    padding-top: 10px;
}

.pcs-itemtable-header {
    font-size: 8pt;
    color: #000000;
    background-color: #f2f3f4;
}

.pcs-itemtable-breakword {
    word-wrap: break-word;
}

.pcs-taxtable-header {
    font-size: 8pt;
    color: #000000;
    background-color: #f2f3f4;
}

.breakrow-inside {
    page-break-inside: avoid;
}

.breakrow-after {
    page-break-after: auto;
}

.pcs-item-row {
    font-size: 8pt;
    border-bottom: 1px solid #9e9e9e;
    background-color: #ffffff;
    color: #000000;
}

.pcs-item-sku {
    margin-top: 2px;
    font-size: 10px;
    color: #444444;
}

.pcs-item-desc {
    color: #333333;
    font-size: 8pt;
}

.pcs-balance {
    background-color: #ffffff;
    font-size: 9pt;
    color: #000000;
}

.pcs-savings {
    font-size: 0pt;
}

.pcs-totals {
    font-size: 8pt;
    color: #000000;
    background-color: #ffffff;
}

.pcs-notes {
    font-size: 8pt;
}

.pcs-terms {
    font-size: 8pt;
}

.pcs-header-first {
    background-color: #ffffff;
    font-size: 8pt;
    color: #000000;
    height: auto;
}

.pcs-status {
    font-size: 15pt;
    border: 3px solid;
    padding: 3px 8px;
}

.billto-section {
    padding-top: 0mm;
    padding-left: 0mm;
}

.shipto-section {
    padding-top: 0mm;
    padding-left: 0mm;
}

@page :first {
    @top-center {
        content: element(header);
    }
    margin-top: 0.200000in;
}

.pcs-template-header {
    padding: 0 0.400000in 0 0.550000in;
    height: 0.200000in;
}

.pcs-template-fill-emptydiv {
    display: table-cell;
    content: " ";
    width: 100%;
}


/* Additional styles for RTL compat */


/* Helper Classes */

.inline {
    display: inline-block;
}

.v-top {
    vertical-align: top;
}

.text-align-right {
    text-align: right;
}

.rtl .text-align-right {
    text-align: left;
}

.text-align-left {
    text-align: left;
}

.rtl .text-align-left {
    text-align: right;
}

.float-section-right {
    float: right;
}

.rtl .float-section-right {
    float: left;
}

.float-section-left {
    float: left;
}

.rtl .float-section-left {
    float: right;
}


/* Helper Classes End */

.item-details-inline {
    display: inline-block;
    margin: 0 10px;
    vertical-align: top;
    max-width: 70%;
}

.total-in-words-container {
    width: 100%;
    margin-top: 10px;
}

.total-in-words-label {
    vertical-align: top;
    padding: 0 10px;
}

.total-in-words-value {
    width: 170px;
}

.total-section-label {
    padding: 5px 10px 5px 0;
    vertical-align: middle;
}

.total-section-value {
    width: 120px;
    vertical-align: middle;
    padding: 10px 10px 10px 5px;
}

.rtl .total-section-value {
    padding: 10px 5px 10px 10px;
}

.tax-summary-description {
    color: #727272;
    font-size: 8pt;
}

.bharatqr-bg {
    background-color: #f4f3f8;
}


/* Overrides/Patches for RTL compat */

.rtl th {
    text-align: inherit;
    /* Specifically setting th as inherit for supporting RTL */
}


/* Overrides/Patches End */


/* Signature styles */

.sign-border {
    width: 200px;
    border-bottom: 1px solid #000;
}

.sign-label {
    display: table-cell;
    font-size: 10pt;
    padding-right: 5px;
}


/* Signature styles End */


/* Subject field styles */

.subject-block {
    margin-top: 20px;
}

.subject-block-value {
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 14pt;
    margin-top: 5px;
}


/* Subject field styles End*/

.pcs-template-bodysection {
    border: 1px solid #9e9e9e;
}

.pcs-itemtable {
    border-top: 1px solid #9e9e9e;
}

.pcs-addresstable {
    width: 100%;
    table-layout: fixed;
}

.pcs-addresstable>thead>tr>th {
    padding: 1px 5px;
    background-color: #f2f3f4;
    font-weight: normal;
    border-bottom: 1px solid #9e9e9e;
}

.pcs-addresstable>tbody>tr>td {
    line-height: 15px;
    padding: 5px 5px 0px 5px;
    vertical-align: top;
    word-wrap: break-word;
}

.invoice-detailstable>tbody>tr>td {
    width: 50%;
    vertical-align: top;
    border-top: 1px solid #9e9e9e;
}

.invoice-detailstable>tbody>tr>td>span {
    width: 45%;
    padding: 1px 5px;
    display: inline-block;
    vertical-align: top;
}

.pcs-itemtable-header {
    font-weight: normal;
    border-right: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
}

.pcs-itemtable-subheader {
    padding: 1px 5px;
    text-align: right;
}

.pcs-item-row {
    border-right: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
}

.pcs-itemtable tr td.pcs-itemtable-subheader:last-child {
    border-right: 1px solid #9e9e9e;
}

.pcs-itemtable tr td.pcs-itemtable-subrow:last-child {
    border-right: 1px solid #9e9e9e;
}

.pcs-itemtable tr td:last-child,
.pcs-itemtable tr th:last-child {
    border-right: 0px;
}

.pcs-itemtable tr td:first-child,
.pcs-itemtable tr th:first-child {
    border-left: 0px;
}

.pcs-itemtable tbody>tr>td {
    padding: 1px 5px;
    word-wrap: break-word;
}

.pcs-totaltable tbody>tr>td {
    padding: 4px 7px 0px;
    text-align: right;
}

.pcs-footer-content {
    border-top: 0px;
}

#tmp_vat_summary_label {
    padding: 4px 4px 3px 7px;
}

.pcs-taxtable-header {
    border-bottom: 1px solid #9e9e9e;
    border-right: 1px solid #9e9e9e;
}

.c1 {
    width: 10%;
    padding: 2px 10px;
    vertical-align: middle;
}

.c2 {
    width: 150px;
    height: 90px;
}

.c3 {
    width: 50%;
    padding: 2px 10px;
    vertical-align: middle;
}

.c4 {
    width: 40%;
    padding: 5px;
    vertical-align: bottom;
}

.c5 {
    border-right: 1px solid #9e9e9e;
    border-top: 1px solid #9e9e9e;
}

.c6 {
    margin-bottom: 0px;
    display: block;
}

.c7 {
    border-top: 1px solid #9e9e9e;
}

.c8 {
    border-right: 1px solid #9e9e9e;
    padding-bottom: 10px;
}

.c9 {
    white-space: pre-wrap;
    line-height: 15px;
}

.c10 {
    padding-bottom: 10px;
}

.text-black {
    color: #000000 !important;
}

.search-field .input-group-prepend .input-group-text {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: 0;
}

.search-field .input-group .input-group-text {
    border-color: #eee;
    transition: .3s;
}

.theme-cyan .sidebar-nav .metismenu ul a:hover::before {
    display: none;
}

.orgs-settings {}

.orgs-settings ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.orgs-settings ul li {}

.orgs-settings ul li a {
    color: #212529;
    display: block;
    padding: 6px 20px;
}

.orgs-settings ul li a:hover {
    color: #fff;
}

.orglist-topband .orgs:hover {
    text-decoration: none;
    color: #fff;
    background-color: #268ddd;
}

.orglist-topband .orgs {
    padding: 0;
    cursor: pointer;
    margin: 5px 0;
}

.m-r-10,
.metismenu>li i,
.navbar-nav ul.email li a .avtar-pic,
.user-account .dropdown .dropdown-menu a i,
.top_counter .icon,
.right_chat .media .media-object {
    margin-right: 5px;
}

.light_version .metismenu ul a:before {
    background: transparent;
}

.custom-tabs {}

.custom-tabs .css-13xfq8m-MuiTabPanel-root {
    padding: 0;
}

.transDataTable td {
    white-space: none !important;
}

.custom-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #000000;
    background: #fbfafa;
}

.custom-tabs .css-1aquho2-MuiTabs-indicator {
    display: none;
}

.custom-tabs .css-1gsv261 {
    border-bottom: 0;
}

button:focus {
    outline: none;
}


/**
 * ==============================================
 * Dot Windmill
 * ==============================================
 */

.dot-windmill {
    position: relative;
    top: -10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    transform-origin: 5px 15px;
    animation: dotWindmill 2s infinite linear;
}

.dot-windmill::before,
.dot-windmill::after {
    content: '';
    display: inline-block;
    position: absolute;
}

.dot-windmill::before {
    left: -8.66px;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
}

.dot-windmill::after {
    left: 8.66px;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
}

@keyframes dotWindmill {
    0% {
        transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
        transform: rotateZ(720deg) translate3d(0, 0, 0);
    }
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #9880ff;
    }
    50%,
    100% {
        background-color: #ebe6ff;
    }
}

.loaderCustomCss {
    background-color: #faf3f3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: start;
    display: flex;
    justify-content: center;
    z-index: 110000;
    overflow: hidden;
}

.float-right {
    float: right !important;
}

.content-column>.header {
    padding: 0 20px;
    margin-bottom: -40px;
}

.separationline {
    border-left: 1px solid #e0e0e0;
    padding-left: 8px;
}

.font-medium,
.item-label {
    font-size: 15px;
}

.content-column>.header h3,
.list-column .header h3,
.list-column.expanded-list-column .list-header .list-title>a,
.list-header h3,
.list-title .filter-title,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.page-header-title,
.salesorder-body .so-details-table.table tbody>tr>td.header-item,
.txn-creation-column .header h3 {
    font-weight: 500;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    padding-right: 10px;
}

.content-column>.header .btn-toolbar {
    padding: 6px 0;
}

.warehouse-card-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 15px;
}

.warehouse-card-list .warehouse-card.wl-card {
    min-height: 210px;
}

.warehouse-card-list .warehouse-card {
    position: relative;
    padding: 10px 20px 0;
    width: 480px;
    min-height: 170px;
    margin: 0 25px 25px 0;
    box-shadow: 0 2px 7px 0 rgb(28 29 83 / 10%);
    border-radius: 6px;
    transition: all .3s ease-in-out;
}

button.btn.btn-primary {
    margin-right: 10px;
}

.warehouse-status-label.status {
    margin-left: 415px;
    padding-bottom: 10px;
}


/* .modal-sm {
    max-width: 426px;
}
.light_version .modal-dialog .modal-content {
  background: #fff;
  width: 488px;
} */


/* .column .fill {
  left: 0;
  right: 0;
  position: initial;
} */


/* .list-header .list-filter .btn {
  padding: 2px 9px;
}
button.close.no-outline {
  margin-left: 410px;
} */

.list-column.column-small+.content-column {
    left: 213px;
    right: 0px;
    position: absolute;
}

.list-column.column-small {
    width: 211px;
    margin-top: 25px;
}

.preferences {
    margin-top: 65px !important;
    margin-left: 181px;
    height: 800px;
    overflow-x: scroll;
}

.prefHeader {
    margin-bottom: -10px;
}

.column .fill1 {
    left: 0;
    right: 0;
    position: absolute;
}

.list-column {
    border-right: 1px solid #c7c6c7;
    width: 376px;
    left: 0px;
}

.column .body_1 {
    top: 74px;
    bottom: 0;
}

customdropdownsleact {}

.customdropdownsleact .css-1s2u09g-control {
    width: max-content;
    border-left: 1px solid #b0c0d6 !important;
    border: none;
    border-radius: 0;
}

.customdropdownsleact .css-1pahdxg-control {
    width: max-content;
    box-shadow: none;
    border-left: 1px solid #b0c0d6 !important;
    border: none;
    border-radius: 0;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
    margin-left: 14px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 6px 11px 41px -28px #a99de7;
}

.content-column .body_1 {
    padding: 0 20px 50px;
    overflow-x: auto;
    display: inline-flex;
}

.custom-tabs .MuiBox-root {}

.pb-75 {
    padding-bottom: 75px !important;
}

.desc {
    margin-left: 37px;
    padding-top: 0px
}

.itemList {
    margin-top: 6px;
    padding-left: 5px;
    padding-right: 5px;
}

.style {
    margin-left: 15px;
    font-size: x-large;
    color: indianred;
}

.itemMeasurement {
    font-size: 13px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-65 {
    margin-top: 65px;
}

.customwidth {
    width: auto !important;
    min-width: auto !important;
}

.txn-creation-column .fixed-actions {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 0 0 0 -20px;
    box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
    background-color: #fff;
    width: calc(100% - 183px);
    z-index: 11;
    /* width: 100%; */
}

.bottom-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
    background-color: #fff;
}

.page {
    height: 650px;
}

.subpage {}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

.manubtncustom {
    width: 14px !important;
    height: 14px !important;
    top: -3px !important;
    margin-right: 0 !important;
}

svg.icon.icon-smcustom {
    height: 14px !important;
    width: 14px !important;
}

.modal-title {
    width: 100%;
}

.react-calendar {
    width: 100% !important;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.customfileupload input[type=file]:after {
    display: none;
}

.customTable#scroll {
    max-height: 440px;
    overflow-y: auto;
}

.customTable h4 {
    margin: 0;
}

.customTable tr {
    border-bottom: 1px solid #efefef;
}

.customTable td {
    padding: 6px 16px;
}

.customTable .css-1s2u09g-control {
    align-content: center;
    height: 40px;
}

.customTable .css-319lph-ValueContainer {
    align-content: center;
    height: 40px;
}

.customTable input[type="number"],
.search-input {
    border: 1px solid #e1e8ed;
    width: 80px;
    height: 38px;
    padding: 5px 8px;
    border-radius: .25rem;
    text-align: end;
}

.customTable input[type="text"] {
    border: 1px solid #e1e8ed;
    width: 80px;
    height: 38px;
    padding: 5px 8px;
    border-radius: .25rem;
    text-align: end;
}

.customTable input[type="textarea"] {
    border: 1px solid #e1e8ed;
    height: 38px;
    padding: 5px 8px;
    border-radius: .25rem;
    text-align: end;
}

.search-input {
    text-align: start;
}

input:focus {
    outline: none;
}

.customTable input:focus {
    outline: none;
}

.warehouse-table {
    max-height: 400px;
    overflow-y: auto;
}

.issueItemTable input {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
}

.issueItemTable input:focus {
    outline: none;
}

.issueItemTable .select-td {
    min-width: 200px;
}

#reportSelect {
    width: 200px;
}

.loading-circle {
    animation: rotate 1.6s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}


/****new css*****/

.custom-height {
    height: 120vh !important;
}


/* .custom-100{ height:66vh !important;} */

.custumpdf {
    margin-bottom: 0 !important;
    height: 100% !important;
}

.custumpdf .details-container {
    margin-bottom: 0 !important;
    height: 100%;
    max-width: 100% !important;
    padding: 27px 0;
}

.custumpdf .details-page {
    max-width: 100% !important;
}

.custumpdf .pcs-header-content {
    height: auto !important;
}

.custumpdf #ember2146 {
    height: 100%;
}

.custumpdf .pcs-template {
    height: 100%;
}

.custumpdf .pcs-template-body {
    height: 100%;
}

.custumpdf .pcs-template-bodysection {
    height: 100%;
    position: relative;
}

.pdf-height-div {
    height: 80vh !important;
    border-bottom: 1px solid #9e9e9e;
}

.pdf-height-div1 {
    height: 55vh !important;
    border-bottom: 1px solid #9e9e9e;
}

.tablescrol {
    height: 137px !important;
    overflow-y: scroll;
    border-bottom: 1px solid #9e9e9e;
    border-top: 1px solid #9e9e9e;
}

.custumpdf .bottom-tr {
    border-top: 1px solid #9e9e9e;
}

.pdf-height-100 {
    height: auto !important;
}

.pcs-item-row {
    font-size: 9pt !important;
}

#itemName {
    width: 250px !important;
}

.hideOverlay {
    display: none;
}

.showOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.requiredTable {
    color: tomato
}





/* .css-1s2u09g-control #itemUnit2{
  border: 1px solid gray !important
} */